import React, { useContext } from "react";
import { graphql } from "gatsby";
import { url, Image } from "../../config/website";
import Layout from "../components/layout";
import HeroIndex from "../components/HeroIndex";
import { MDXRenderer } from "gatsby-plugin-mdx";
// import { allLanguagesLinks } from "../helpers/consts";
// import HrefLang from "../components/SEO/HrefLang";
import { ContextLocale } from "../context/ContextLocale";
import { content_wrapper } from "../styles/modules/content.module.css";
import AdWithPlaceholder from "../components/Ads/AdWithPlaceholder";

function IndexPage({ data }) {
  const { setLang } = useContext(ContextLocale);

  const { mdx } = data;
  setLang(mdx.frontmatter.locale ? mdx.frontmatter.locale : "es");

  const embeddedImagesObject = mdx => {
    if (mdx.frontmatter.embeddedImages) {
      return mdx.frontmatter.embeddedImages[0].name.includes("-")
        ? mdx.frontmatter.embeddedImages.reduce((images, image) => {
            images[image.name.replace(/-/g, "_")] = images[image.name] || {
              ...image.childImageSharp,
              publicURL: image.publicURL,
            };

            return images;
          }, {})
        : mdx.frontmatter.embeddedImages.reduce((images, image) => {
            images[image.name] = images[image.name.replace(/-/g, "_")] || {
              ...image.childImageSharp,
              publicURL: image.publicURL,
            };

            return images;
          }, {});
    } else return;
  };
  // <HrefLang links={allLanguagesLinks.home} />
  return (
    <Layout isHome={true} locale={mdx.frontmatter.locale}>
      <HeroIndex
        title={mdx.frontmatter.title}
        locale={mdx.frontmatter.locale}
        description={mdx.frontmatter.metaDescription}
      />

      <AdWithPlaceholder
        ins={`<!-- ptdc-color -->
        <ins class="adsbygoogle"
             style="display:block"
             data-ad-client="ca-pub-3833126112632805"
             data-ad-slot="5789449190"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>`}
      />

      <article className={content_wrapper}>
        <MDXRenderer embeddedImages={embeddedImagesObject(mdx)}>
          {mdx.body}
        </MDXRenderer>
      </article>
    </Layout>
  );
}

export const Head = ({ data }) => {
  const { mdx } = data;

  const canonicalUrl = `${url}/${
    mdx.frontmatter.locale !== "es" ? `${mdx.frontmatter.locale}/` : ""
  }`;

  const websiteData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name:
      mdx.frontmatter.locale === "es"
        ? "paletas de colores online"
        : mdx.frontmatter.locale === "en"
        ? "online color palettes"
        : mdx.frontmatter.locale === "pt"
        ? "paletas de cores online"
        : "",
    url: canonicalUrl,
    image: `${url}/${Image}`,
    about:
      mdx.frontmatter.locale === "es"
        ? "sitio web para generar paletas de colores e ideas para encontrar la paleta perfecta para un proyecto"
        : mdx.frontmatter.locale === "en"
        ? "website for generating color palettes and ideas for finding the perfect palette for a project"
        : mdx.frontmatter.locale === "pt"
        ? "site para gerar paletas de cores e ideias para encontrar a paleta perfeita para um projeto"
        : "",
    publisher: {
      "@type": "Organization",
      name: "Paletadecolores.online",
      logo: {
        "@type": "ImageObject",
        url: `${url}/paletadecolores-online-logo.png`,
        height: 200,
        width: 200,
      },
    },
  };

  return (
    <>
      <title>{mdx.frontmatter.metaTitle}</title>
      <meta name="description" content={mdx.frontmatter.metaDescription} />
      <link rel="canonical" href={canonicalUrl} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(websiteData) }}
      />
    </>
  );
};

export const data = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        metaTitle
        metaDescription
        date
        slug
        locale
        modified
        embeddedImages {
          publicURL
          name
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export default IndexPage;
