export const heroLinks = {
  es: {
    generate: { anchor: "Generar paleta", slug: "/generar-paleta/" },
    create: { anchor: "Crear paleta", slug: "/crear-paleta/" },
  },
  en: {
    generate: { anchor: "Generate palette", slug: "/en/generate-palette/" },
    create: { anchor: "Create palette", slug: "/en/create-palette/" },
  },
  pt: {
    generate: { anchor: "Gerar paleta", slug: "/pt/gerar-paleta/" },
    create: { anchor: "Criar paleta", slug: "/pt/criar-paleta/" },
  },
  fr: {
    generate: { anchor: "Générer palette", slug: "/fr/generer-palette/" },
    create: { anchor: "Créer palette", slug: "/fr/creer-palette/" },
  },
};
